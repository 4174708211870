<template>
  <div class="relative xl:h-screen lg:overflow-hidden bg-primary-500 xl:bg-white
  before:hidden before:xl:block before:content-[''] before:w-[57%] before:-mt-[28%] before:-mb-[16%] before:-ml-[13%]
  before:absolute before:inset-y-0 before:left-0 before:transform before:rotate-[-4.5deg]
  before:bg-primary-100 before:rounded-[100%] after:hidden after:xl:block after:content-[''] after:w-[57%]
  after:-mt-[20%] after:-mb-[13%] after:-ml-[13%] after:absolute after:inset-y-0 after:left-0 after:transform
  after:rotate-[-4.5deg] after:bg-primary-500 after:rounded-[100%]">
    <div v-if="resetSuccess" class="intro-y mt-4 text-green-600">
      {{ trans('leakblock.forgot.successMessage') }}
    </div>
    <div class="!max-w-[1800px] container relative z-10 sm:px-10">
      <div class="block grid-cols-2 gap-4 xl:grid">
        <div class="hidden min-h-screen flex-col xl:flex">
          <div class="my-auto">
            <img class="intro-y -mt-16 w-1/2" src="/build/images/illustrations/login.svg" alt="Leakblock Login">
            <div class="intro-y mt-10 text-4xl font-medium leading-tight text-white" v-html="getHeadlineText()"></div>
            <div class="intro-y mt-5 text-lg text-white text-opacity-70">
              {{ trans('leakblock.login.protectData') }}
            </div>
          </div>
        </div>
        <div class="mt-10 xl:my-10 flex xl:h-screen py-16 xl:py-5 xl:my-0 xl:h-auto xl:py-0">
          <div class="mx-auto my-auto w-full rounded-md bg-white px-5 py-8 shadow-md sm:w-3/4 sm:px-8 lg:w-2/4 xl:ml-20 xl:w-auto xl:bg-transparent xl:p-0 xl:shadow-none">
            <transition name="fade" mode="out-in">
              <div v-if="showSuccessMessage" key="success" class="text-center">
                <div class="flex justify-center mb-8">
                  <img class="intro-y -mt-16 w-1/4" src="/build/images/illustrations/mail_sent.svg" alt="Email sent">
                </div>
                <h2 class="intro-y text-2xl font-bold mb-4">{{ successMessage }}</h2>
                <p class="intro-y mb-4">{{ getSuccessSubtext() }}</p>
                <button @click="handleSuccessAction" class="intro-y bg-primary-500 text-white px-4 py-2 rounded hover:bg-primary-600 transition">
                  {{ getSuccessButtonText() }}
                </button>
              </div>
              <div v-else key="form">
                <h2 class="intro-y text-center text-2xl font-bold xl:text-left xl:text-3xl">
                  {{ getFormTitle() }}
                </h2>
                <div v-if="sessionExpired" class="intro-y mt-2 text-red-600">
                  {{ trans('leakblock.login.sessionExpired') }}
                </div>
                <div class="intro-y mt-2 text-center text-slate-400 xl:hidden">
                  {{ trans('leakblock.login.protectData') }}
                </div>
                <form @submit.prevent="submitForm">
                  <div class="intro-y mt-8">
                    <input type="email" v-model="email" name="email" id="email" :placeholder="trans('leakblock.login.emailPlaceholder')" required class="intro-y block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    <template v-if="formType === 'register'">
                      <input type="text" v-model="firstname" name="firstname" id="firstname" :placeholder="trans('leakblock.register.firstname')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                      <input type="text" v-model="lastname" name="lastname" id="lastname" :placeholder="trans('leakblock.register.lastname')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    </template>
                    <template v-if="formType !== 'forgot'">
                      <input type="password" v-model="password" name="password" id="password" :placeholder="trans('leakblock.login.passwordPlaceholder')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    </template>
                    <input v-if="formType === 'register'" type="password" v-model="confirmPassword" name="confirm-password" id="confirm-password" :placeholder="trans('leakblock.register.confirmPasswordPlaceholder')" required class="intro-y mt-4 block min-w-full px-4 py-3 xl:min-w-[350px] transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus:border-primary-500 focus:border-opacity-40">
                    <div v-if="formType === 'register'" class="intro-y mt-4 flex text-xs text-slate-600 sm:text-sm">
                      <div class="mr-auto flex items-center">
                        <input type="checkbox" required class="transition-all duration-100 ease-in-out shadow-sm border-slate-200 cursor-pointer rounded focus:ring-4 focus:ring-offset-0 focus:ring-primary-500 focus:ring-opacity-20 [&[type='radio']]:checked:bg-primary-500 [&[type='radio']]:checked:border-primary-500 [&[type='radio']]:checked:border-opacity-10 [&[type='checkbox']]:checked:bg-primary-500 [&[type='checkbox']]:checked:border-primary-500 [&[type='checkbox']]:checked:border-opacity-10 [&:disabled:not(:checked)]:bg-slate-100 [&:disabled:not(:checked)]:cursor-not-allowed [&:disabled:checked]:opacity-70 [&:disabled:checked]:cursor-not-allowed mr-2 border" id="accept-agb">
                        <label class="cursor-pointer select-none" for="accept-agb" v-html="trans('leakblock.register.acceptAGB', { 'agbLink': trans(`leakblock.footer.termsUrl`) })">
                        </label>
                      </div>
                    </div>
                  </div>
                  <div v-if="errorMessage" class="intro-y mt-4 text-red-600">{{ errorMessage }}</div>
                  <div v-if="formType === 'login'" class="intro-y mt-4 flex text-xs text-slate-600 sm:text-sm">
                    <a @click.prevent="setFormType('forgot')" href="#" class="cursor-pointer">{{ trans('leakblock.login.forgotPasswordLink') }}</a>
                  </div>
                  <div class="intro-y mt-5 text-center xl:mt-8 xl:text-left">
                    <SubmitButton :is-submitting="isSubmitting">
                      {{ getSubmitButtonText() }}
                    </SubmitButton>
                    <button v-if="formType !== 'forgot'" type="button" @click="toggleForm" class="mt-3 w-full xl:w-40 xl:mt-0 xl:ml-3 border text-slate-500 px-4 py-3 align-top rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none transition duration-200 hover:bg-secondary/20">
                      {{ formType === 'login' ? trans('leakblock.login.signUpLink') : trans('leakblock.register.loginLink') }}
                    </button>
                    <button v-else type="button" @click="setFormType('login')" class="mt-3 w-full xl:w-40 xl:mt-0 xl:ml-3 border text-slate-500 px-4 py-3 align-top rounded-md font-medium cursor-pointer focus:ring-4 focus:ring-primary-500 focus:ring-opacity-20 focus-visible:outline-none transition duration-200 hover:bg-secondary/20">
                      {{ trans('leakblock.register.loginLink') }}
                    </button>
                  </div>
                </form>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { trans } from "../../../js/utilities/leakblock-translations";
import axios from 'axios';
import {useRoute, useRouter} from "vue-router";
import SubmitButton from "../components/Buttons/SubmitButton.vue";

const props = defineProps({
  csrf_token_register: String,
  loginUrl: String,
  registerNewUrl: String,
  dashboardUrl: String,
  forgotPasswordUrl: String
});

const route = useRoute();
const router = useRouter();

const formType = ref('login');
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
const firstname = ref('');
const lastname = ref('');
const errorMessage = ref('');
const successMessage = ref('');
const isSubmitting = ref(false);
const showSuccessMessage = ref(false);
const resetSuccess = ref(false);
const sessionExpired = ref(false);

const setFormType = (type) => {
  formType.value = type;
  errorMessage.value = '';
};

const toggleForm = () => {
  setFormType(formType.value === 'login' ? 'register' : 'login');
};

const validateForm = () => {
  if (!email.value) {
    errorMessage.value = trans('leakblock.validation.enterEmail');
    return false;
  }
  if (formType.value !== 'forgot' && !password.value) {
    errorMessage.value = trans('leakblock.validation.enterPassword');
    return false;
  }
  if (formType.value === 'register') {
    if (!firstname.value || !lastname.value) {
      errorMessage.value = trans('leakblock.validation.fillAllFields');
      return false;
    }
    if (password.value !== confirmPassword.value) {
      errorMessage.value = trans('leakblock.register.passwordsDoNotMatch');
      return false;
    }
    if (password.value.length < 8 || !/[!@#$%^&*(),.?":{}|<>]/.test(password.value)) {
      errorMessage.value = trans('leakblock.register.passwordRequirements');
      return false;
    }
  }
  return true;
};

const submitForm = async () => {
  if (!validateForm()) return;

  isSubmitting.value = true;
  errorMessage.value = '';

  try {
    let response;
    const startTime = Date.now();

    switch (formType.value) {
      case 'login':
        response = await axios.post(props.loginUrl, {
          email: email.value,
          password: password.value
        });
        if (response.data.redirectUrl) {
          window.location.href = response.data.redirectUrl;
        } else {
          window.location.href = props.dashboardUrl;
        }
        break;
      case 'register':
        response = await axios.post(props.registerNewUrl, {
          email: email.value,
          password: password.value,
          firstname: firstname.value,
          lastname: lastname.value,
          token: props.csrf_token_register
        });
        break;
      case 'forgot':
        response = await axios.post(props.forgotPasswordUrl, { email: email.value });
        break;
    }

    const elapsedTime = Date.now() - startTime;
    const remainingTime = Math.max(0, 1500 - elapsedTime);

    // Ensure the spinner shows for at least 2 seconds
    await new Promise(resolve => setTimeout(resolve, remainingTime));

    if (response.data.success) {
      if (formType.value === 'login') {
        window.location.href = props.dashboardUrl;
      } else {
        successMessage.value = response.data.success;
        showSuccessMessage.value = true;
      }
    }
  } catch (error) {
    errorMessage.value = error.response?.data?.error || trans('leakblock.error.occurredDuring', { 'action': trans(`leakblock.${formType.value}.actionText`) });
  } finally {
    isSubmitting.value = false;
  }
};

const getSuccessSubtext = () => {
  switch (formType.value) {
    case 'register':
      return trans('leakblock.register.success');
    case 'forgot':
      return trans('leakblock.forgot.email');
    default:
      return '';
  }
};

const getSuccessButtonText = () => {
  switch (formType.value) {
    case 'register':
      return trans('leakblock.login.goToLogin');
    case 'forgot':
      return trans('leakblock.login.backToLogin');
    default:
      return '';
  }
};

const handleSuccessAction = () => {
  setFormType('login');
  showSuccessMessage.value = false;
};

const getFormTitle = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.headline');
    case 'register': return trans('leakblock.register.headline');
    case 'forgot': return trans('leakblock.forgot.headline');
  }
};

const getHeadlineText = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.headlineTextHtml');
    case 'register': return trans('leakblock.register.headlineTextHtml');
    case 'forgot': return trans('leakblock.forgot.headlineTextHtml');
  }
};

const getSubmitButtonText = () => {
  switch (formType.value) {
    case 'login': return trans('leakblock.login.signInButton');
    case 'register': return trans('leakblock.register.registerButton');
    case 'forgot': return trans('leakblock.forgot.resetButton');
  }
};

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  sessionExpired.value = urlParams.get('session_expired') === 'true';

  // Optional: Clear the URL parameter without refreshing the page
  if (sessionExpired.value) {
    const newUrl = window.location.pathname + window.location.hash;
    window.history.replaceState({}, document.title, newUrl);
  }
});
</script>