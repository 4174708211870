import './bootstrap.js';
import './styles/app.css';
import './scripts/charts';
import 'flowbite/dist/flowbite.js';
import moment from "moment/moment";
import {registerVueControllerComponents} from "@symfony/ux-vue";


registerVueControllerComponents(require.context('./vue/user/controllers', true, /\.vue$/));

export function formatDate(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY');
    }
    return value;
}